
import { computed, defineComponent, reactive, toRefs, watch, onBeforeUnmount } from 'vue'

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup(props) {
    const state = reactive({
      list: Array<string>(),
      current: -1,
      timer: -1
    })

    const imageList = computed(() => {
      const list = []
      for (let i = 1; i <= 16; i++) {
        // eslint-disable-next-line
        list.push(require(`@/assets/images/coin/${i}.png`))
      }
      return list
    })

    watch(() => props.show, (isShow) => {
      if (isShow) {
        state.timer && window.clearInterval(state.timer)
        state.timer = window.setInterval(() => {
          if (state.current > 16) state.current = 0
          else state.current += 1
        }, 80)
      } else {
        window.clearInterval(state.timer)
        state.current = -1
      }
    })

    onBeforeUnmount(() => {
      window.clearInterval(state.timer)
      state.current = -1
    })

    return {
      imageList,
      ...toRefs(state)
    }
  }
})
