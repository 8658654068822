/*
 * @Description: 游戏列表
 */
import https from '@/utils/https'
import { RootObject } from '@/model/rootObject'
import { ContentType, Method } from 'axios-mapper'
import { ListItem } from '@/model/listModel'
import { List } from '@/model/list'

// 获取列表
export const getGameList = (url: string, params: any) => {
  return https().request<RootObject<List<ListItem>>>(`api/demoGame/${url}`, Method.GET, params, ContentType.form)
}
