import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-034c8fff"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "gameList" }
const _hoisted_2 = { class: "game" }
const _hoisted_3 = ["onClick", "title"]
const _hoisted_4 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Effect = _resolveComponent("Effect")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("section", {
    class: "list J_List",
    style: _normalizeStyle(_ctx.bgImage ? {backgroundImage: `url(${_ctx.bgImage})`} : {})
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
          return (_openBlock(), _createElementBlock("figure", {
            key: item.gamecode,
            onClick: ($event: any) => (_ctx.onClick(item)),
            title: item.name,
            onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMouseenter && _ctx.onMouseenter(...args))),
            onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMouseleave && _ctx.onMouseleave(...args))),
            onTouchstart: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onTouchstart && _ctx.onTouchstart(...args))),
            class: "J_Game_Item"
          }, [
            _createElementVNode("img", {
              src: item.small_img,
              alt: item.name
            }, null, 8, _hoisted_4),
            _createElementVNode("figcaption", null, _toDisplayString(item.name), 1)
          ], 40, _hoisted_3))
        }), 128)),
        _createVNode(_component_Effect, {
          show: _ctx.show,
          class: "J_Effect"
        }, null, 8, ["show"])
      ])
    ]),
    _withDirectives(_createVNode(_component_el_pagination, {
      layout: "prev, pager, next",
      total: _ctx.total,
      "page-size": _ctx.listQuery.pageSize,
      class: "list-pagination",
      onCurrentChange: _ctx.handleCurrentChange
    }, null, 8, ["total", "page-size", "onCurrentChange"]), [
      [_vShow, _ctx.total > 0]
    ])
  ], 4))
}