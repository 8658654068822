
import { defineComponent, onBeforeMount, onMounted, reactive, toRefs, watch } from 'vue'
import { getGameList } from '@/apis/list'
import { ListItem } from '@/model/listModel'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import Effect from './components/Effect.vue'

function getPosition(element: any) {
  // 计算x坐标
  let actualLeft = element.offsetLeft
  let current = element.offsetParent
  while (current !== null) {
    actualLeft += current.offsetLeft
    current = current.offsetParent
  }
  // 计算y坐标
  let actualTop = element.offsetTop
  current = element.offsetParent
  while (current !== null) {
    actualTop += (current.offsetTop + current.clientTop)
    current = current.offsetParent
  }
  // 返回结果
  return { x: actualLeft, y: actualTop }
}

export default defineComponent({
  components: {
    Effect
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const options = {
      autoPlay: 1,
      bringToFront: true
    }
    const dataMap = reactive({
      bgImage: '',
      gameType: '',
      total: 0,
      listQuery: {
        language: store.state.app.language,
        page: 1,
        pageSize: 15
      },
      list: Array<ListItem>(),
      show: false
    })
    const methods = reactive({
      async getList() {
        if (!dataMap.gameType) return
        const res = await getGameList(dataMap.gameType, dataMap.listQuery)
        const list: ListItem[] = res?.data?.list ?? []
        dataMap.total = res?.data?.total ?? 0
        dataMap.list = list
      },
      handleCurrentChange(current: number) {
        dataMap.listQuery.page = current
        methods.getList()
      },
      onClick(item: ListItem) {
        window.open(`${store.state.app.config.webUrl}/api/game_link?gamecode=${item.gamecode}` +
          `&language=${store.state.app.language}&gt=${item.gt}`, '_blank')
      },
      onMouseenter(e: any) {
        const position = getPosition(e.target)
        const el = window.document.querySelector('.J_Effect') as any
        const game = window.document.querySelector('.J_Game_Item') as any

        if (el) el.style = `width:${game.offsetWidth + 50}px;left:${position.x - 20}px;top:${position.y - 20}px`
        dataMap.show = true
      },
      onMouseleave() {
        dataMap.show = false
      },
      onTouchstart(e: any) {
        methods.onMouseenter(e)
      }
    })

    onBeforeMount(() => {
      switch (route.query.type) {
        case '1':
          dataMap.gameType = 'slot'
          break
        case '2':
          dataMap.gameType = 'hunter'
          break
        case '3':
          dataMap.gameType = 'chess'
          break
        case '4':
          dataMap.gameType = 'arcade'
          break
        default:
          dataMap.gameType = ''
      }
    })

    onMounted(() => {
      const el = window.document.querySelector('.J_List')
      if (store.state.app.device === 1 && el) {
        el.classList.add('m-list')
      }

      if (store.state.app.config.resource) {
        dataMap.bgImage = `${store.state.app.config.resource}/bimg/${store.state.app.language}/game/${dataMap.gameType}.jpg`
        methods.getList()
      }
    })

    watch(() => store.state.app.config.resource, (n, o) => {
      if (dataMap.gameType && n && n !== o) {
        dataMap.bgImage = `${n}/bimg/${store.state.app.language}/game/${dataMap.gameType}.jpg`
        methods.getList()
      }
    })

    watch(() => store.state.app.language, (n) => {
      dataMap.bgImage = `${store.state.app.config.resource}/bimg/${n}/game/${dataMap.gameType}.jpg`
      dataMap.listQuery.language = n
      methods.getList()
    })

    return {
      options,
      ...toRefs(dataMap),
      ...toRefs(methods)
    }
  }
})
